<script lang="ts">
	import { FetchState } from "$src/types/FetchState";
	import BooksLoading from "./BooksLoading.svelte";
	import Search from "./Search.svelte";
  import { booksStore } from "$src/components/stores/books";
	import Books from "./Books.svelte";
	import type { HomePage } from "$src/types/HomePage";
	import ContentWrapper from "../commons/ContentWrapper.svelte";

  export let data: HomePage;
</script>

<div  class="mt-24 overflow-hidden ">
  <ContentWrapper>
    <h2 
      id="books-heading"
      class="text-[20px] leading-[24px] font-bold scroll-mt-20 xl:text-[28px] xl:leading-[34px]"
    >
      {data.listHeading}
    </h2>
    <div class="mt-6">
      <Search />
    </div>
    <div 
      class="flex flex-wrap -mx-3 mt-4"
      data-testid="books-list"
      aria-busy={$booksStore.fetchState !== FetchState.SUCCESS}
    >
      {#if $booksStore.fetchState !== FetchState.SUCCESS}
        <BooksLoading />
      {:else}
        <Books data={data} />
      {/if}
    </div>
  </ContentWrapper>
</div>
<script>
	import { booksStore, loadBooks } from "$src/components/stores/books";
  import intersection from "$src/lib/intersection";
	import BooksLoading from "./BooksLoading.svelte";
</script>

{#key $booksStore.books.length }
  <div 
    class="flex flex-wrap" 
    use:intersection={{ callback: loadBooks }}
  >
    <BooksLoading count={6} />
  </div>
{/key}
<script lang="ts">
	import SkeletonLoader from "../commons/SkeletonLoader.svelte";
  import BookCard from "./BookCard.svelte";

  export let count = 20;
</script>

{#each new Array(count).fill(1) as item}
  <BookCard>
    <div class="py-3 px-5 border-b border-transparent">
      <div class="pt-[3px] pb-[4px] h-[19px] w-[120px] ">
        <SkeletonLoader />
      </div>
    </div>
    <div class="py-[29px] px-5">
      <div class="pt-[3px] pb-[4px] w-[120px]">
        <SkeletonLoader />
      </div>
      <div class="pt-[3px] pb-[4px] w-[160px]">
        <SkeletonLoader />
      </div>
    </div>
    <div class="flex items-center border-t border-transparent py-2 px-5">
      <div class="pt-[3px] pb-[4px] w-[120px]">
        <SkeletonLoader />
      </div>
      <div class="ml-auto w-[90px]">
        <SkeletonLoader class="h-[36px] rounded-full bg-secondary"/>
      </div>
    </div>
  </BookCard>
{/each}
<script lang="ts">
	import { PUBLIC_API_URL, PUBLIC_STRAPI_TOKEN } from '$env/static/public';
	import { FetchState } from '$src/types/FetchState';
	import type { HomePage } from '$src/types/HomePage';
	import Button from '../commons/Button.svelte';
	import Checkbox from '../commons/Checkbox.svelte';
	import CloseButton from '../commons/CloseButton.svelte';
	import Input from '../commons/Input.svelte';
	import Popup from '../commons/Popup.svelte';
	import { focusedBookStore } from '../stores/focused-book';
	import { followUpPopup } from '../stores/follow-up-popup';
	import { snackbarStore } from '../stores/snackbar';
	import MessageSmall from './MessageSmall.svelte';
	import PopupHeading from './PopupHeading.svelte';

	export let data: HomePage;

	let name: string = '';
	let email: string = '';
	let phone: string = '';

	let bookId: number | null = null;
	let fetchState = FetchState.IDLE;

	focusedBookStore.subscribe((focusedBook) => {
		bookId = focusedBook?.id ?? null;
	});

	const onSubmit = async () => {
		try {
			fetchState = FetchState.LOADING;

			const booksUrl = new URL(`${PUBLIC_API_URL}/book/${bookId}/donated`);

			const res = await fetch(booksUrl, {
				method: 'POST',
				headers: {
					authorization: `Bearer ${PUBLIC_STRAPI_TOKEN}`,
					'Content-Type': 'application/json'
				},
				body: JSON.stringify({
					donatorName: name,
					donatorEmail: email,
					donatorPhone: phone
				})
			});

			const { status } = res;

			if (status === 400) {
				fetchState = FetchState.FAILURE;
				snackbarStore.update((store) => ({
					...store,
					snackbars: [
						...store.snackbars,
						{ message: 'Моля уверете се че всичко полета са валидни.' }
					]
				}));
				return;
			}

			if (status === 409) {
				fetchState = FetchState.FAILURE;
				snackbarStore.update((store) => ({
					...store,
					snackbars: [...store.snackbars, { message: 'Тази книжка вече е дарена.' }]
				}));
				return;
			}

			if (status < 200 || status >= 300) {
				fetchState = FetchState.FAILURE;
				snackbarStore.update((store) => ({
					...store,
					snackbars: [
						...store.snackbars,
						{ message: 'Възникна проблем при обработването на заявката, моля опитайте по-късно.' }
					]
				}));
				return;
			}

			fetchState = FetchState.SUCCESS;
			focusedBookStore.set(null);
			followUpPopup.set(true);
		} catch (e: any) {
			fetchState = FetchState.FAILURE;
			snackbarStore.update((store) => ({
				...store,
				snackbars: [
					...store.snackbars,
					{ message: 'Възникна проблем при обработването на заявката, моля опитайте по-късно.' }
				]
			}));

      throw e;
		}
	};
</script>

{#if $focusedBookStore}
	<Popup 
    onOverlayClick={() => ($focusedBookStore = null)}
    testId="donate-popup"
  >
		<PopupHeading>
			{data.formHeading}
		</PopupHeading>
		<div class="mt-5">
			<MessageSmall>
				{@html data.formContent}
			</MessageSmall>
		</div>
		<form class="mt-6" on:submit|preventDefault={onSubmit}>
			<fieldset disabled={fetchState === FetchState.LOADING}>
				<Input 
          placeholder="Име" 
          name="name" 
          required 
          onChange={(newName) => (name = newName)}
          testId="donate-name"
        />
				<div class="flex flex-wrap -mx-3">
					<div class="mt-4 basis-[280px] flex-grow mx-3">
						<Input
							placeholder="Електронна поща"
							type="email"
							name="email"
							required
              testId="donate-email"
							onChange={(newEmail) => (email = newEmail)}
						/>
					</div>
					<div class="mt-4 basis-[280px] flex-grow mx-3">
						<Input
							placeholder="Телефон"
							type="phone"
							name="phone"
							required
              testId="donate-phone"
							onChange={(newPhone) => (phone = newPhone)}
						/>
					</div>
				</div>
				<div class="pl-2 mt-6">
					{#each data.formCheckboxes as checkbox, index}
						<Checkbox name="form-checkbox-{index}" label={checkbox.content} required />
					{/each}
				</div>
				<div class="mt-6">
					<Button 
            type="submit" 
            loading={fetchState === FetchState.LOADING}
            testId="donate-button"
          >
						{data.formButtonText}
					</Button>
				</div>
			</fieldset>
		</form>
		<CloseButton class="top-2 right-2" onClick={() => ($focusedBookStore = null)} />
	</Popup>
{/if}

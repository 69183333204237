<script lang="ts">
	import type { HomePage } from "$src/types/HomePage";
	import ContentWrapper from "./ContentWrapper.svelte";
	import SocialRow from "./SocialRow.svelte";

  export let data: HomePage;
</script>
<div class="py-5 border-text/30 border-t">
  <ContentWrapper>
    <SocialRow
      contactEmail={data.contactEmail}
      facebookUrl={data.facebookUrl}
      instagramUrl={data.instagramUrl}
    />
  </ContentWrapper>
</div>
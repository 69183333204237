<script lang="ts">
  export let onClick: null | (() => void) = null;
  export let type: 'button' | 'submit' = 'button';
  export let big: boolean = false;
  export let disabled: boolean = false;
  export let loading: boolean = false;
  export let testId: string | undefined = undefined;
</script>

<button 
  on:click={() => onClick && onClick()}
  class={`
    w-full bg-primary px-5 py-2 rounded-full text-sm text-white font-semibold outline-none
    hover:bg-primary/90
    focus:ring-primary focus:ring-2 focus:ring-offset-1 
    ${loading ? 'animate-pulse pointer-events-none' : ''}
    ${disabled ? 'opacity-80 pointer-events-none' : ''}
    ${big ? 'px-10' : 'px-5'}
  `}
  disabled={disabled || loading}
  type={type}
  data-testid={testId}
>
  <slot />     
</button>
<script lang="ts">
	import type { Book } from "$src/types/Book";
	import Button from "../commons/Button.svelte";
	import BookCard from "./BookCard.svelte";
  import { focusedBookStore } from "../stores/focused-book";

  export let book: Book;

  let header = [ 
    book?.recepientName,
    book?.recepientAge ? ', ' : '',
    book?.recepientAge,
    book?.recepientAge ? ' г.' : '',
  ].filter((item) => Boolean(item)).join('');
</script>

<BookCard>
  <div class="flex py-3 px-5 font-semibold border-b border-accent/10">
    <h4 class="relative">
      <span class="relative {book?.donated ? 'text-accent' : ''}">{header}</span>
    </h4>
  </div>
  <div class="px-5 py-8 text-[#3e3e3e] font-semibold">
    {book?.bookName?.replace(/;/g, ' ')?.replace(/\s+/, ' ') ?? ''}
  </div>
  <div class="flex items-center py-2 px-5 border-t border-accent/10 min-h-[57px]">
    {#if !book?.donated}
      <div class="py-1">
        {book?.recepientCity}
      </div>
    {/if}
    <div class="ml-auto">
      {#if !book?.donated}
        <Button 
          onClick={() => $focusedBookStore = book}
          testId="donate-button-{book.id}"
        >
          Дари
        </Button>
      {:else}
        <div 
          class="relative translate-x-[10px]"
          data-testid="success-{book.id}"
        >
          <div class="
            absolute top-1/2 left-1/2 rounded-full w-[110px] h-[110px] border-[5px] border-accent/50
            -translate-x-1/2 -translate-y-1/2
          "/>
          <div class="
            absolute top-1/2 left-1/2 rounded-full w-40 h-40 border-[20px] border-accent/50
            -translate-x-1/2 -translate-y-1/2
          "/>
          <p class="relative font-semibold rotate-[4deg] text-accent ">
            Магьосникът е намерен
          </p>
        </div>
      {/if}
    </div>
  </div>
</BookCard>
<script lang="ts">
  import type { Donator } from "$src/types/HomePage";
	import { getCloudinarySrc } from "$src/lib/get-cloudinary-src";
	import Image from "../commons/Image.svelte";

  export let donator: Donator;
</script>

<div class="basis-[120px] mt-4 mx-4 max-w-[300px] first:basis-[240px] md:flex-grow-0">
  <Image
    src={getCloudinarySrc(donator.logo.data.attributes.url)}
    aspectRatio={donator.logo.data.attributes.height / donator.logo.data.attributes.width}
    alt={donator.logo.data.attributes.alternativeText || ''}
    eager={true}
  />
</div>
<svg
	width="22"
	height="22"
	class="w-full h-full"
	viewBox="0 0 22 22"
	fill="none"
	xmlns="http://www.w3.org/2000/svg"
>
  <path
		d="M16.5596 6.65899C16.8525 6.3661 16.8525 5.89123 16.5596 5.59833C16.2667 5.30544 15.7919 5.30544 15.499 5.59833L16.5596 6.65899ZM5.59947 15.4978C5.30658 15.7907 5.30658 16.2656 5.59947 16.5585C5.89237 16.8514 6.36724 16.8514 6.66013 16.5585L5.59947 15.4978ZM15.499 5.59833L5.59947 15.4978L6.66013 16.5585L16.5596 6.65899L15.499 5.59833Z"
		fill="#172F38"
	/>
  <path
		d="M6.61236 5.5976C6.31947 5.30471 5.84459 5.30471 5.5517 5.5976C5.25881 5.89049 5.25881 6.36537 5.5517 6.65826L6.61236 5.5976ZM15.4512 16.5578C15.7441 16.8506 16.219 16.8506 16.5119 16.5578C16.8047 16.2649 16.8047 15.79 16.5119 15.4971L15.4512 16.5578ZM5.5517 6.65826L15.4512 16.5578L16.5119 15.4971L6.61236 5.5976L5.5517 6.65826Z"
		fill="#172F38"
	/>
</svg>

<script lang="ts">
	import getImageObjectFitClass from "$lib/get-image-object-fit-class";
	import { ImageLayout, ImageObjectFit } from "$src/types/Image";
	import intersection from "$lib/intersection";

  export let eager = false;
  export let layout: ImageLayout = ImageLayout.RESPONSIVE; 
  export let objectFit: ImageObjectFit | null = null;
  export let aspectRatio: number | null = null; 
  export let src: string; 
  export let srcSet: string = '';
  export let alt: string = '';
  export let sizes: string = '';

  const isResponsive = layout === ImageLayout.RESPONSIVE;
  const objectFitClass = getImageObjectFitClass(objectFit);

  if (isResponsive && !aspectRatio) throw new Error("Please pass aspect ratio for reposive images");
  
  if (!isResponsive && !objectFit) throw new Error('Please set object fit for fill images');

  const typeSafeAspectRatio = isResponsive ? aspectRatio! : 1;

  let loaded = eager;

  function handleIntersecting() {
    loaded = true;
  }
</script>

{#if isResponsive}
  <div 
    class="relative w-full h-0"
    style={`padding-bottom: ${typeSafeAspectRatio * 100}%`}
  >
    <img
      use:intersection={{ callback: handleIntersecting, rootMargin: '200px', eager: eager }}
      class={`absolute w-full h-full top-0 left-0 ${objectFitClass}`}
      src={eager || loaded ? src : null}
      srcset={srcSet && (eager || loaded) ? srcSet : null}
      sizes={sizes ? sizes : null}
      alt={alt || ''}
    /> 
  </div>
{:else}
  <img
    use:intersection={{ callback: handleIntersecting, rootMargin: '200px', eager: eager }}
    class={`w-full h-full ${objectFitClass}`}
    src={eager || loaded ? src : null}
    srcset={srcSet && (eager || loaded) ? srcSet : null}
    sizes={sizes ? sizes : null}
    alt={alt || ''}
  />  
{/if}
<script lang="ts">
	import { booksStore } from '$src/components/stores/books';
	import type { HomePage } from '$src/types/HomePage';
	import Book from './Book.svelte';
	import BooksEmpty from './BooksEmpty.svelte';
	import BooksMoreLoading from './BooksMoreLoading.svelte';

  export let data: HomePage;
</script>

<div class="min-h-screen w-full">
  {#if $booksStore.books.length === 0}
    <BooksEmpty text={data.emptyListText}/>
  {:else}
    <div class="flex flex-wrap w-full">
      {#each $booksStore.books as book}
        <Book book={book} />
      {/each}
      {#if $booksStore.hasMore }
        <BooksMoreLoading />
      {/if}
    </div>
  {/if}
</div>
<script lang="ts">
  import { removeSnackbar, snackbarStore } from "../stores/snackbar";
	import Card from "./Card.svelte";
	import CloseButton from "./CloseButton.svelte";
</script>

<div class="fixed bottom-3 left-5 w-full max-w-[400px] z-20">
  {#each $snackbarStore.snackbars as snackbar}
    <div class="relative mb-3">
      <Card>
        <div class="flex pl-5 py-2 pr-10">
          {snackbar.message}
        </div>
        <CloseButton onClick={() => removeSnackbar(snackbar)} />
      </Card> 
    </div>
  {/each}
</div>
<script lang="ts">
  import Header from "$components/commons/Header.svelte";
	import DonatePopup from "$src/components/index/DonatePopup.svelte";
	import Seo from "$src/components/commons/Seo.svelte";
	import Snackbars from "$src/components/commons/Snackbars.svelte";
	import BooksWrapper from "$src/components/index/BooksWrapper.svelte";
	import Hero from "$src/components/index/Hero.svelte";
	import Message from "$src/components/index/Message.svelte";
	import type { PageData } from "./$types";
	import FollowUpPopup from "$src/components/index/FollowUpPopup.svelte";
	import Donators from "$src/components/index/Donators.svelte";
  import Footer from '$src/components/commons/Footer.svelte';
	import { onDestroy, onMount } from "svelte";
	import { loadBooks } from "$src/components/stores/books";
	import { startSocketClient } from "$src/lib/socket-client";

  export let data: PageData;

  let closeSocketConnection: () => void;

  onMount(() => { 
    loadBooks();
    closeSocketConnection = startSocketClient();
  })

  onDestroy(() => { 
    closeSocketConnection?.()
  })
</script>

{#if data.seo}
  <Seo seo={data.seo} />
{/if}

<div class="relative min-h-screen">
  <div class="absolute top-0 left-0 h-full w-full overflow-hidden pointer-events-none">
    <div class="absolute bottom-[calc(100%-80px)] right-auto lg:bottom-[calc(100%-200px)]">
      <div class="transform translate-x-[-30%]">
        <div class="
          w-[148px] h-[148px] rounded-full bg-secondary
          lg:w-[386px] lg:h-[368px]
        "/>
      </div>
    </div>
  </div> 
  <Header 
    contactEmail={data.contactEmail}
    facebookUrl={data.facebookUrl}
    instagramUrl={data.instagramUrl}
    buttonText={data.headerButtonText}
  /> 
  <div class="relative pb-20 xl:pb-10 xl:pt-20">
    <Hero {data} />
		<Donators {data} />
    <Message {data} />
    <BooksWrapper {data}/>
  </div>
  <Footer {data} />
</div> 
<DonatePopup {data} />
<FollowUpPopup {data}/>
<Snackbars />
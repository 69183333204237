<script lang="ts">
	import type { HomePage } from '$src/types/HomePage';
	import ContentWrapper from '../commons/ContentWrapper.svelte';
	import Donator from './Donator.svelte';

	export let data: HomePage;
</script>

<div class="mt-12 overflow-hidden">
  <ContentWrapper>
    <div class="flex flex-wrap justify-center items-center -mx-4 md:flex-nowrap md:justify-between">
      {#each data.donators as donator}
        <Donator {donator} />
      {/each}
    </div>
  </ContentWrapper>
</div>

<script lang="ts">
	import type { HomePage } from "$src/types/HomePage";
	import ContentWrapper from "../commons/ContentWrapper.svelte";

  export let data: HomePage;
</script>

<div class="mt-24">
  <ContentWrapper>
    <div class="
      text-[16px]/[24px] text-text font-bold
      xl:text-[18px]/[26px]
      [&_h2]:text-[24px]/[29px] [&_h2]:xl:text-[30px]/[38px]
      [&_h3]:text-[22px]/[28px] [&_h3]:xl:text-[28px]/[36px]
      [&_h4]:text-[20px]/[26px] [&_h4]:xl:text-[26px]/[34px]
      [&_h5]:text-[18px]/[24px] [&_h5]:xl:text-[24px]/[32px]
      [&_p]:mt-[28px] [&_p]:text-text/80 [&_p]:font-semibold
      [&_a]:underline
    ">
      {@html data.message}
    </div>
  </ContentWrapper>
</div>

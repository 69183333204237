<svg class="w-full h-full" width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path 
    class="stroke-text/80"
    d="M9 11H71C74.3137 11 77 13.6863 77 17V63C77 66.3137 74.3137 69 71 69H9C5.68629 69 3 66.3137 3 63V17C3 13.6863 5.68629 11 9 11Z" 
    stroke-width="7"
    stroke-linejoin="round"
  />
  <path 
    class="stroke-text/80"
    d="M4.5 12L40 40.5L75.5 12"
    stroke-width="7"
    stroke-linejoin="round"
  />
</svg>
  
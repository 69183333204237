<script lang="ts">
	import { FetchState } from "$src/types/FetchState";
	import Input from "../commons/Input.svelte";
  import SkeletonLoader from "../commons/SkeletonLoader.svelte";
	import { booksStore, searchBooks } from "../stores/books";
</script>

<div class="w-[240px]">
  <Input 
    onChange={searchBooks}
    placeholder="Търсене"
    name="search"
  />
</div>
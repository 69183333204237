<script lang="ts">
  export let href: string;
  export let label: string;
</script>

<a 
  href={href}
  target="_blank"
  rel="noreferrer"
  class="flex items-center"
  aria-label={label}
>
  <div class="
    w-5 h-5 mx-5 
    md:mx-2
    hover:opacity-70
  ">
    <slot name="icon" />
  </div>
</a> 
<script lang="ts">
  export let name: string;
  export let label: string;
  export let required: boolean | undefined = undefined;
</script>

<div class="mt-5 flex"> 
  <input
    id={name}
    name={name}
    required={required}
    type="checkbox"
    class="
      mt-[3px] w-4 h-4 shrink-0 accent-primary
      outline-none focus:ring-2 ring-offset-1
      disabled:opacity-80
      focus:ring-primary
      md:mt-[2px]
    "
  />
  <label for={name} class="ml-5 text-sm cursor-pointer">
    {label}
  </label>
</div>
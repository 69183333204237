<script lang="ts">
  export let onChange: (value: string) => void;
  export let type: string = 'text';
  export let placeholder: string | undefined = undefined;
  export let name: string;
  export let required: boolean | undefined = undefined;
  export let testId: string | undefined = undefined;
</script>

<input
  class="
    px-5 py-2 w-full bg-white border-2 border-accent/20 rounded-full
    outline-none focus:ring-primary focus:ring-2 ring-offset-1
    disabled:opacity-60
  "
  type={type}
  placeholder={placeholder}
  name={name}
  required={required}
  data-testid={testId}
  on:input={(e) => onChange(e.currentTarget.value)}
/>
import { PUBLIC_API_URL } from "$env/static/public";
import { io } from "socket.io-client";

export const socket = io(PUBLIC_API_URL.replace('api', ''), { 
  transports: ["polling"],
  autoConnect: false
});

export function startSocketClient() {
  socket.connect();
  return () => socket.close();
}
<script lang="ts">
  import type Seo from '../../types/Seo';

  export let seo: Seo;
</script>

<svelte:head>
  <meta property="og:type" content="website" />
  <link rel="icon" type="image/png" href="/favicon.png" />
  {#if seo.title}
    <title>{seo.title}</title>
    <meta content={seo.title} property="og:title" />
    <meta content={seo.title} property="twitter:title" />
  {/if}
  {#if seo.description}
    <meta content={seo.description} name="description" />
    <meta content={seo.description} property="og:description" />
    <meta content={seo.description} property="twitter:description" />  
  {/if}
  {#if seo.image}
    <meta property="og:image" content={seo.image.data.attributes.url} />
    <meta property="twitter:image" content={seo.image.data.attributes.url} />
  {/if}
</svelte:head>
import { writable } from "svelte/store";

interface Snackbar { 
  message: string
}

interface SnackbarStore { 
  snackbars: Snackbar[]
}

export const snackbarStore = writable<SnackbarStore>({ snackbars: [] });

export const removeSnackbar = (snackbar: Snackbar) => { 
  snackbarStore.update((value) => ({ snackbars: value.snackbars.filter((storeSnackbar) => storeSnackbar !== snackbar) }));
}
<script lang="ts">
	import Button from './Button.svelte';
	import ContentWrapper from './ContentWrapper.svelte';
	import SocialRow from './SocialRow.svelte';

  export let contactEmail: string;
  export let facebookUrl: string;
  export let instagramUrl: string;
  export let buttonText: string;

  const onButtonClick = () => { 
    document.querySelector('#books-heading')?.scrollIntoView({ behavior: 'smooth' })
  }

</script>

<div class="w-full border-b-2 border-accent/10 z-10 backdrop-filter backdrop-blur-xl">
  <ContentWrapper>
    <div class="flex items-center py-[10px]">
      <p class="block text-sm uppercase font-semibold text-text">
        Направи магия
      </p>
      <div class="flex ml-auto">
        <div class="hidden md:flex md:mr-5">
          <SocialRow 
            contactEmail={contactEmail}
            facebookUrl={facebookUrl}
            instagramUrl={instagramUrl}
          />
        </div>
        <Button onClick={onButtonClick} testId="popup-button">
          {buttonText || 'Направи магия'}
        </Button>
      </div>
    </div>
  </ContentWrapper>
</div>
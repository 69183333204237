<script lang="ts">
	import Close from "../icons/Close.svelte";
  let cs = '';
  
  export { cs as class};
  export let onClick: (e: MouseEvent) => void
  export let testId: string = '';
</script>

<button
  class={`absolute top-0 right-0 flex items-center justify-center w-[46px] h-[46px] ${cs}`}
  on:click={onClick}
  data-testid={testId || undefined}
>
  <div class="w-5 h-5">
    <Close />
  </div>
</button>
import { ImageObjectFit } from "$src/types/Image";

export default function getImageObjectFitClass(objectFit: ImageObjectFit | null) {
  if (objectFit === ImageObjectFit.CONTAIN) { 
    return 'object-contain'
  }

  if (objectFit === ImageObjectFit.COVER) { 
    return 'object-cover'
  }

  return '';
}
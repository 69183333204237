<script lang="ts">
	import Image from '$components/commons/Image.svelte';
	import { getCloudinarySrc, getCloudinarySrcSet } from '$src/lib/get-cloudinary-src';
	import type { HomePage } from '$src/types/HomePage';
	import ContentWrapper from '../commons/ContentWrapper.svelte';

	export let data: HomePage;
</script>

<ContentWrapper>
  <div class="pt-10 md:flex md:flex-row-reverse xl:pt-0">
    <div class="
      flex justify-center 
      md:w-1/2 md:items-start md:pl-5 md:pt-14
      xl:pt-16
    ">
      <div class="relative pr-[44px] md:sticky md:top-0 lg:pr-[19px] lg:ml-auto">
        <div class="
          absolute top-0 right-0 w-[167px] h-[167px] bg-secondary rounded-full
          lg:w-[500px] lg:h-[500px] lg:top-auto lg:left-auto lg:right-0 lg:bottom-[-23px]
          xl:w-[590px] xl:h-[590px]
        "/>
        <div class="w-[243px] md:w-[300px] lg:w-[400px] xl:w-[500px]">
          <Image
            aspectRatio={1}
            srcSet={getCloudinarySrcSet(data.hero.data.attributes.url, [400, 600, 800, 1000])}
            sizes="(max-width: 767px) 243px, (max-width: 1023px) 300px, (max-width: 1279px) 400px, 500px"
            src={getCloudinarySrc(data.hero.data.attributes.url)}
            eager={true}
            alt={data.hero.data.attributes.alternativeText || ''}
          />
        </div>
      </div>
    </div>
    <div class="flex flex-col justify-center mt-16 text-center md:text-left md:mt-0 md:w-1/2 md:pr-5">
      <h1 class="text-[24px] leading-[29px] text-text font-bold xl:text-[32px]">
        {data.heading}
      </h1>
      <div class="
        mt-4 text-text/80 font-semibold leading-[24px]
        xl:mt-[28px] xl:text-[18px] xl:leading-[26px]
      ">
        <p>
          {data.subheading}
        </p>
      </div>
    </div>
  </div>
</ContentWrapper>

interface Options { 
  callback: (element: Element) => void
  eager?: boolean
  rootMargin?: string
}

export default function intersection(node: HTMLElement, opts: Options) {
  const {
    callback,
    eager = false,
    rootMargin = '0px',
  } = opts;

  if (eager) return; 

  if (!('IntersectionObserver' in window)) {
    callback(node)
    return; 
  }

  const observer = new IntersectionObserver((entries: IntersectionObserverEntry[]) => {
    const entry = entries.filter(entry => entry.target === node)[0]; 

    if (!entry) return;

    if(!entry.isIntersecting) return;

    callback(entry.target);

    observer.unobserve(entry.target);
  }, { rootMargin })

  observer.observe(node)

  return { 
    destroy: () => observer.unobserve(node)
  }
}
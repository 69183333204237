<script lang="ts">
	import type { HomePage } from '$src/types/HomePage';
	import Button from '../commons/Button.svelte';
	import CloseButton from '../commons/CloseButton.svelte';
  import Popup from '../commons/Popup.svelte';
	import { followUpPopup } from '../stores/follow-up-popup';
	import MessageSmall from './MessageSmall.svelte';
	import PopupHeading from './PopupHeading.svelte';

  export let data: HomePage;
</script>

{#if $followUpPopup}
<!-- svelte-ignore security-anchor-rel-noreferrer -->
  <Popup 
    testId="follow-up-popup"
    onOverlayClick={() => ($followUpPopup = false)}
  >
    <a href="https://nmd.bg/dari" target="_blank" class="cursor-wand">
      <div class="-m-5 p-5">
        <PopupHeading>
          {data.followUpFormHeading}
        </PopupHeading>
        <div class="mt-6">
          <MessageSmall>
            {@html data.followUpFormContent}
          </MessageSmall>
        </div>
        <div class="mt-6 pointer-events-none">
          <Button onClick={() => {}}>
            {data.followUpFormButtonText}
          </Button>
        </div>
      </div>
    </a>
    <div class="absolute top-3 right-1 w-[46px] h-[46px]">
      <CloseButton 
        testId="close-follow-up-popup"
        onClick={() => $followUpPopup = false}
      />
    </div>
  </Popup>

{/if}

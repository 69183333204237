interface Options { 
  width?: number
  height?: number
}

export function getCloudinarySrc(src: string, options?: Options) { 
  const filename = src.split('/').pop(); 

  const { width, height } = options || {};

  return `https://res.cloudinary.com/brestertech/image/upload/f_auto,q_100${
    width ? `,w_${width}` : ''
  }${
    height ? `,h_${height}` : ''
  }/napravimagia/${filename}`
}

export function getCloudinarySrcSet(src: string, sizes: number[]) {
  return sizes.reduce((acc, size) => {
    return `${acc}${acc !== '' ? ', ' : ''}${getCloudinarySrc(src, { width: size })} ${size}w`
  }, '');
}
<script lang="ts">
  export let onOverlayClick: () => void;
  export let testId: string | undefined = undefined;
</script>

<!-- svelte-ignore a11y-click-events-have-key-events -->
<div
	class="fixed top-0 left-0 flex flex-col p-5 w-full h-full overflow-auto bg-black/5 z-10 popup"
  data-testid={testId}
	on:click={onOverlayClick}
>
	<div
		on:click={(e) => e.stopPropagation()}
		class="relative p-5 m-auto max-w-[640px] bg-white shadow-md rounded-md"
	>
    <slot />
  </div>
</div>

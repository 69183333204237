<script lang="ts">
	import Facebook from "../icons/Facebook.svelte";
	import Instagram from "../icons/Instagram.svelte";
	import Mail from "../icons/Mail.svelte";
	import SocialLink from "./SocialLink.svelte";

  export let contactEmail: string;
  export let facebookUrl: string;
  export let instagramUrl: string;
</script>
<div class="flex items-center">
  <SocialLink
    href={`mailto:${contactEmail}`}
    label="Изпрати имейл"
  >
    <Mail slot="icon" />
  </SocialLink>
  <SocialLink
    href={facebookUrl}
    label="Facebook старница"
  >
    <Facebook slot="icon" />
  </SocialLink>
  <SocialLink
    href={instagramUrl}
    label="Instagram старница"
  >
    <Instagram slot="icon" />
  </SocialLink>
</div> 
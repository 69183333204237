import { PUBLIC_API_URL, PUBLIC_STRAPI_TOKEN } from '$env/static/public';
import type { Book } from '$src/types/Book';
import { writable } from 'svelte/store';
import { FetchState } from '$src/types/FetchState';
import { snackbarStore } from './snackbar';
import { socket } from '$src/lib/socket-client';

export interface BooksStore {
	fetchState: FetchState;
	books: Book[];
  hasMore: boolean;
  search: string | undefined
}

let lastRequest: Promise<Response>;

let localStore: BooksStore = {
	fetchState: FetchState.LOADING,
	books: [],
  hasMore: true,
  search: '',
}

export const booksStore = writable<BooksStore>(localStore);

booksStore.subscribe(store => localStore = store);

socket.on("bookUpdate", (data) => {
  const { id, donated } = data as { id: string, donated: boolean };

  const numericId = parseInt(id);

  const item = localStore.books.find(book => book.id === numericId);

  if (!item) return;

  const index = localStore.books.indexOf(item);

  booksStore.update(store => {
    store.books[index].donated = donated;

    return store;
  })
});

export async function searchBooks(newSearch: string) {
  booksStore.update(store => ({
    ...store,
    search: newSearch,
    books: [],
    fetchState: FetchState.LOADING
  })); 
  
  await loadBooks();
}

export async function loadBooks() {
  try {
    const request = fetch(`${PUBLIC_API_URL}/books/page/next`, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        authorization: `Bearer ${PUBLIC_STRAPI_TOKEN}`
      },
      body: JSON.stringify({ 
        search: localStore.search,
        exclude: localStore.books.map(book => book.id),
      }),
    });

    lastRequest = request; 

    const res = await request;

    if (request !== lastRequest) return;

    const { books: newBooks, hasNextPage }: { books: Book[], hasNextPage: boolean } = await res.json();

    if (request !== lastRequest) return;

    booksStore.update((store) => {
      return { 
        ...store,
        books: [
          ...store.books,
          ...newBooks
        ],
        fetchState: FetchState.SUCCESS,
        hasMore: hasNextPage,
      }
    });
  } catch (e: unknown) { 
    snackbarStore.update(snackbarStore => ({ 
      ...snackbarStore,
      snackbars: [
        ...snackbarStore.snackbars,
        { message: 'Възникна проблем при зареждането на книгите. Моля презаредете старницата.' }
      ]
    }));

    throw e;
  }
}
